export type ReadOperationLog = {
  id: number;
  is_system?: true;
  device_code?: string;
  user_id?: number;
  name?: string;
  event: 'connect' | 'disconnect' | 'switch';
  action_at: string;
  data?: WriteOperationLogSwitchEventData;
};
export type PaginationOperationLog = {
  data: ReadOperationLog[];
  current_page: number;
  per_page: number;
  last_page: number;
};
export enum PowerSwitchState {
  ON, //on
  OFF, //off
  UNKNOWN, //不明
}

export type PowerSwitch = {
  number: number; //スイッチ番号
  name?: string;
  state: PowerSwitchState;
};

export type ModuleWith = {
  deviceCode: string; //communication_modulesテーブルのdeviceCode
  name?: string;
  unitsByNumber: Record<number, PowerUnit>; //接続してきた親機が「R=」で情報を渡してくる
};

export type PowerUnit = {
  number: number; //子機番号
  switchesByNumber: Record<number, PowerSwitch>;
};

export type WriteOperationLogSwitchEventData = {
  devicesByDeviceCodes: Record<string, ModuleWith>;
};
