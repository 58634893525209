import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { operationLogGetsSuccess } from '@/slicers/operation_log';
import { setResponse } from '@/slicers/response';
import { setFlashError } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/sites')
class OperationLogsRequest {
  caller!: TApiCaller;

  index(
    site: number,
    date: string,
    page?: number,
    eventFilter?: string,
    clientFilter?: string,
    selectedModule?: string,
    filterStartAt?: string,
    filterEndAt?: string
  ) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.POST(
          `/${site}/operation_logs`,
          {
            date,
            page,
            eventFilter,
            clientFilter,
            selectedModule,
            filterStartAt,
            filterEndAt,
          } // Add eventFilter in POST body
        );

        if (response.status === 200) {
          dispatch(operationLogGetsSuccess(response.data));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('ログ一覧の取得に失敗しました。'));
        }
      },
    };
  }
}

const operationLogsRequest = new OperationLogsRequest();

export default operationLogsRequest;
