import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { useParams, useHistory } from 'react-router-dom';
import { ClientRouteParams, SitesRouteParams } from '@/routes/ClientRoute';
import styles from './styles.module.scss';

import { initOperationLogState } from '@/slicers/operation_log';
import operationLogsRequest from '@/api/operation_logs';
import moment from 'moment';
import { setLoading } from '@/slicers/loading';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import { DatetimeTextField } from '@/components/atoms/Datetime';
import { getLoginUserSelector } from '@/selector/users';
import { BackButton } from '@/components/atoms/Buttons';
import { UserTypeOperator } from '@/slicers/users';
import operationPageRequest from '@/api/operation_page';
import { findModulesWithOperationPageSelectorFactory } from '@/selector/operation-page';
import { PowerSwitchState, ReadOperationLog } from '@/types';

const SiteOperationLogsList: React.FC = () => {
  const { site: siteId } = useParams<ClientRouteParams & SitesRouteParams>();

  const dispatch = useDispatch();
  const logs = useSelector((state: RootState) =>
    Object.values(state.operationLogState.operationLogsById)
  );
  const dataLogs = useSelector(
    (state: RootState) => state.operationLogState.paginationOperationLog
  );

  const history = useHistory();

  const loginUser = useSelector(getLoginUserSelector);
  const moduleWithByDeviceCode = useSelector((state: RootState) =>
    findModulesWithOperationPageSelectorFactory(state)
  );

  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  type EventFilter = 'connection' | 'switch' | 'all';
  const [eventFilter, setEventFilter] = useState<EventFilter>('all');

  type ClientFilter = 'user' | 'device' | 'system' | 'all';
  const [clientFilter, setClientFilter] = useState<ClientFilter>('all');

  const [filterStartAt, setFilterStartAt] = useState('00:00');
  const [filterEndAt, setFilterEndAt] = useState('23:59');
  const [selectedModule, setSelectedModule] = useState<string>('all');
  const [currentPage, setCurrentPage] = useState(dataLogs.current_page ?? 1);

  useEffect(() => {
    dispatch(operationPageRequest.index(siteId).request());
  }, [dispatch, siteId]);

  useEffect(() => {
    const func = async () => {
      await dispatch(setLoading(true));
      await dispatch(
        operationLogsRequest
          .index(
            Number(siteId),
            date,
            currentPage,
            eventFilter,
            clientFilter,
            selectedModule,
            filterStartAt,
            filterEndAt
          )
          .request()
      );
      await dispatch(setLoading(false));
    };
    func();

    return () => {
      dispatch(initOperationLogState);
    };
  }, [
    dispatch,
    date,
    siteId,
    currentPage,
    eventFilter,
    clientFilter,
    selectedModule,
    filterStartAt,
    filterEndAt,
  ]);

  const handlePageChange = (
    direction: 'prev' | 'next' | 'goLast' | 'goFirst'
  ) => {
    if (direction === 'goFirst') {
      setCurrentPage(1);
    }
    if (direction === 'prev' && dataLogs.last_page) {
      setCurrentPage(currentPage - 1);
    }
    if (direction === 'next' && currentPage < dataLogs.last_page) {
      setCurrentPage(currentPage + 1);
    }
    if (direction === 'goLast' && dataLogs.last_page > 1) {
      setCurrentPage(dataLogs.last_page);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          paddingTop: '20px',
        }}
      >
        <FormControl style={{ minWidth: '120px' }}>
          <InputLabel id="demo-simple-select-label">クライアント</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={clientFilter}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setClientFilter(event.target.value as any);
              setCurrentPage(1);
            }}
          >
            <MenuItem value={'all'}>すべて</MenuItem>
            <MenuItem value={'user'}>ユーザー</MenuItem>
            <MenuItem value={'device'}>親機</MenuItem>
            <MenuItem value={'system'}>システム</MenuItem>
          </Select>
        </FormControl>
        {(clientFilter === 'all' || clientFilter === 'device') && (
          <FormControl style={{ minWidth: '120px' }}>
            <InputLabel id="device-code">指令盤</InputLabel>
            <Select
              labelId="device-code"
              value={selectedModule}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectedModule(event.target.value as any);
                setCurrentPage(1);
              }}
            >
              <MenuItem value="all">すべて</MenuItem>
              {Object.values(moduleWithByDeviceCode).map((mdl) => (
                <MenuItem key={mdl.deviceCode} value={mdl.deviceCode}>
                  {mdl.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl style={{ minWidth: '120px' }}>
          <InputLabel id="demo-simple-select-label">イベント</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={eventFilter}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setEventFilter(event.target.value as any);
              setCurrentPage(1);
            }}
          >
            <MenuItem value={'all'}>すべて</MenuItem>
            <MenuItem value={'connection'}>接続・切断</MenuItem>
            <MenuItem value={'switch'}>スイッチ</MenuItem>
          </Select>
        </FormControl>
        <div style={{ display: 'inline-block' }}>
          <InputLabel
            id="demo-simple-select-label"
            style={{ fontSize: '12px' }}
          >
            日付
          </InputLabel>
          <div style={{ display: 'flex' }}>
            <DatetimeTextField
              timeFormat={false}
              value={date}
              dateFormat={'YYYY-MM-DD'}
              onChange={(props: any) => {
                setDate(props.format('YYYY-MM-DD'));
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
        <div style={{ display: 'inline-block' }}>
          <InputLabel
            id="demo-simple-select-label"
            style={{ fontSize: '12px' }}
          >
            時間帯
          </InputLabel>
          <div style={{ display: 'flex' }}>
            <DatetimeTextField
              dateFormat={false}
              value={filterStartAt}
              timeFormat={'HH:mm'}
              onChange={(event: any) => {
                setFilterStartAt(event.format('HH:mm'));
                setCurrentPage(1);
              }}
            />
            ~
            <DatetimeTextField
              dateFormat={false}
              value={filterEndAt}
              timeFormat={'HH:mm'}
              onChange={(event: any) => {
                setFilterEndAt(event.format('HH:mm'));
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
        <div className={styles.goBack_btn}>
          {loginUser?.type! < UserTypeOperator && (
            <BackButton onClick={history.goBack} />
          )}
        </div>
      </div>
      <div>
        {!logs.length ? (
          <div>データがありません</div>
        ) : (
          logs.map((log) => displayLog(log))
        )}
      </div>
      <div className={styles.container_bottom}>
        <div className={styles.container_pagination}>
          <Button
            onClick={() => handlePageChange('goFirst')}
            disabled={currentPage === 1}
            className={styles.pagination_button}
          >
            <img src="/assets/icons/pagination-first.svg" alt="First" />
          </Button>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange('prev')}
            className={styles.pagination_button}
          >
            <img src="/assets/icons/pagination-previous.svg" alt="Prev" />
          </Button>
          <div>
            {currentPage} / {dataLogs.last_page ?? 1}
          </div>
          <Button
            className={styles.pagination_button}
            disabled={
              currentPage === dataLogs.last_page || dataLogs.last_page === null
            }
            onClick={() => handlePageChange('next')}
          >
            <img src="/assets/icons/pagination-next.svg" alt="Next" />
          </Button>
          <Button
            className={styles.pagination_button}
            onClick={() => handlePageChange('goLast')}
            disabled={
              currentPage === dataLogs.last_page || dataLogs.last_page === null
            }
          >
            <img src="/assets/icons/pagination-last.svg" alt="Last" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SiteOperationLogsList;

const displayActionAt = (actionAt: string) => {
  const actionAtMoment = moment(actionAt);
  return (
    <span>
      [<span>{actionAtMoment.format('YYYY-MM-DD')}</span>,<span> </span>
      <span>{actionAtMoment.format('HH:mm:ss')}</span>]
    </span>
  );
};

const displayLog = (log: ReadOperationLog) => {
  let text = '';
  if (log.event === 'connect' || log.event === 'disconnect') {
    text =
      (log.user_id
        ? `ユーザーId: ${log.user_id}, ユーザー名: ${log.name}`
        : `デバイスコード: ${log.device_code}, 親機名: ${log.name}`) + 'が';

    text += (log.event === 'connect' ? '接続' : '切断') + 'しました';
  }

  if (log.event === 'switch') {
    text = log.user_id
      ? `ユーザーId: ${log.user_id}, ユーザー名: ${log.name}がスイッチ操作しました`
      : log.device_code
      ? `デバイスコード: ${log.device_code}, 親機名: ${log.name}のスイッチが変更されました`
      : 'スケジュールが実行されました';
  }

  const powerSwitchStateToText = (state: PowerSwitchState) => {
    switch (state) {
      case PowerSwitchState.ON:
        return 'ON';
      case PowerSwitchState.OFF:
        return 'OFF';
      case PowerSwitchState.UNKNOWN:
        return '不明';
    }
  };

  const displayData = (data?: ReadOperationLog) => {
    return (
      data?.data &&
      Object.values(data.data)?.map((data) => (
        <div>
          <div>
            {Object.values(data).map((moduleWith) => (
              <div style={{ marginLeft: `1rem` }}>
                {true && (
                  // {data.deviceCode !== undefined && (
                  <div>
                    <div>デバイスコード:{moduleWith.deviceCode}</div>
                    <div>親機名:{moduleWith.name}</div>
                  </div>
                )}
                {Object.values(moduleWith.unitsByNumber).map((unit) => (
                  <div style={{ marginLeft: `1rem` }}>
                    <div>列番号: {unit.number}</div>
                    <div style={{ marginLeft: `1rem` }}>
                      {Object.values(unit.switchesByNumber).map(
                        (powerSwitch) => (
                          <div key={powerSwitch.number}>
                            <div>スイッチ番号: {powerSwitch.number}</div>
                            <div>スイッチ名: {powerSwitch.name}</div>
                            <div style={{ marginLeft: `1rem` }}>
                              状態:
                              {powerSwitchStateToText(powerSwitch.state)}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))
    );
  };
  return (
    <div>
      <div>
        {displayActionAt(log.action_at)} {text}
      </div>
      {displayData(log)}
    </div>
  );
};
