import { PaginationOperationLog, ReadOperationLog } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OperationLogState = {
  operationLogsById: Record<number, ReadOperationLog>;
  paginationOperationLog: PaginationOperationLog;
};

export const initialStateOperationLog: OperationLogState = {
  operationLogsById: {},
  paginationOperationLog: {
    data: [],
    current_page: 1,
    per_page: 10,
    last_page: 1,
  },
};

const operationLog = createSlice({
  name: 'operationLog',
  initialState: initialStateOperationLog,
  reducers: {
    operationLogGetsSuccess(
      state,
      { payload }: PayloadAction<PaginationOperationLog>
    ) {
      const safeData = payload.data;
      state.paginationOperationLog = {
        ...payload,
        data: safeData,
      };

      state.operationLogsById = {};
      let index = 0;
      for (const log of safeData) {
        state.operationLogsById[index] = log;
        index++;
      }
    },
    initOperationLogState(state) {
      state.operationLogsById = {};
      state.paginationOperationLog = {
        data: [],
        current_page: 1,
        per_page: 10,
        last_page: 1,
      };
    },
  },
});

export const { operationLogGetsSuccess, initOperationLogState } =
  operationLog.actions;

export default operationLog.reducer;
